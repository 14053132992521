import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import 'intersection-observer';
import cx from 'classnames';
import { useInView } from 'react-intersection-observer';
import fit from 'object-fit-images';
import s from './Image.module.scss';

const propTypes = {
  imageSet: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
      media: PropTypes.string,
      type: PropTypes.string,
    }),
  ).isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
  contain: PropTypes.bool,
  cover: PropTypes.bool,
  gradient: PropTypes.bool,
  fade: PropTypes.bool,
  lazy: PropTypes.bool,
  lqipImg: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  bottom: PropTypes.bool,
  center: PropTypes.bool,
  overlay: PropTypes.bool,
  autoHeight: PropTypes.bool,
  staticBackground: PropTypes.bool,
  dataAttribute: PropTypes.string,
  onError: PropTypes.func,
};

const defaultProps = {
  alt: '',
  className: '',
  contain: false,
  cover: false,
  gradient: false,
  fade: false,
  lazy: false,
  lqipImg: null,
  bottom: false,
  center: false,
  overlay: false,
  autoHeight: false,
  staticBackground: false,
  dataAttribute: '',
  onError: () => {},
};

// Set the parent element to `position: relative` to make use of contain/cover
// This allows the imageSet to be absolutely positioned and applied like a
// background image in the container

const Image = ({
  imageSet,
  alt,
  className,
  contain,
  cover,
  gradient,
  fade,
  lazy,
  lqipImg,
  bottom,
  center,
  overlay,
  autoHeight,
  staticBackground,
  dataAttribute,
  onError,
}) => {
  const [imgLoaded, setImgLoaded] = useState(false);
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });
  useEffect(() => {
    fit();
  });

  const onImgLoad = () => {
    /* istanbul ignore next */
    if (lqipImg) setImgLoaded(true);
  };
  const dynamicAttribute = {};
  if (dataAttribute) dynamicAttribute[dataAttribute] = 'true';

  return (
    <>
      <picture
        className={cx({
          [s.cover]: cover,
          [s.static]: staticBackground,
          [s.gradient]: gradient,
          [s.overlay]: overlay,
          [s.fade]: fade,
          [s.contain]: contain,
          [s.autoHeight]: autoHeight,
          [s.picture]: true,
        })}
        ref={ref}
        {...dynamicAttribute}
      >
        {((!lazy && !lqipImg) || inView) &&
          imageSet.map((image, index) => {
            if (index === imageSet.length - 1) {
              return (
                <img
                  className={[
                    /* istanbul ignore next */
                    lqipImg && !imgLoaded ? s.hidden : '',
                    bottom ? s.bottom : '',
                    center ? s.center : '',
                    className,
                  ].join(' ')}
                  src={image.src}
                  alt={alt}
                  onLoad={onImgLoad}
                  onError={onError}
                  key={`${image.src}${image.media}${image.type}`}
                />
              );
            }
            return (
              <source
                media={image.media}
                srcSet={image.src}
                type={image.type ? image.type : ''}
                key={`${image.src}${image.media}${image.type}`}
              />
            );
          })}
      </picture>
      {lqipImg && !imgLoaded && (
        <img
          className={[s.loaderImg, bottom ? s.bottom : '', center ? s.center : '', className].join(
            ' ',
          )}
          src={lqipImg}
          alt={alt}
        />
      )}
    </>
  );
};

Image.propTypes = propTypes;
Image.defaultProps = defaultProps;
export default Image;
